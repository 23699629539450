import { defineStore } from "pinia";
import { ShoppingCartItem, ShoppingCartState } from "./type";
import { useTokenStore } from "../TokenOperationStoreGroup/TokenOperation";
import axios from "axios";
import { IUniversalDataReturns } from "@/store/type";

export const useShoppingCartStore = defineStore("useShoppingCartStore", {
  state: (): ShoppingCartState => ({
    products: [],
    loading: true,
    accept: false,
    count: 0,
  }),
  getters: {
    netAmount: (state): string => {
      return state.products.reduce((sum, item) => {
        const itemPrice = Number(item.price) || 0;
        const itemAmount = Number(item.amount) || 0;
        return sum + (itemPrice * itemAmount);
      }, 0).toFixed(2);
    },
    
    vat: (state): number => {
      const netAmount = state.products.reduce((sum, item) => {
        const itemPrice = Number(item.price) || 0;
        const itemAmount = Number(item.amount) || 0;
        return sum + (itemPrice * itemAmount);
      }, 0);
    
      const vat = netAmount * 0.081;
      return Math.round(vat * 100) / 100;
    },
    
    totalPrice(): number {
      const netAmount = Number(this.netAmount) || 0;
      const vat = this.vat || 0;
      const total = netAmount + vat;
      return Math.round(total * 100) / 100;
    },
    totalCartItems: (state) => {
      return state.products.reduce((total, item) => {
        const amountString = item.amount.toString();
        return total + parseFloat(amountString);
      }, 0);
    },
    
  },
  actions: {
    async getCart(page = 1) {
      try {
        this.loading = true;
        const tokenStore = useTokenStore();
        const { data } = await axios.get<IUniversalDataReturns<ShoppingCartItem>>(process.env.VUE_APP_SERVER + 'api/shopping_carts/',
          {
              headers: { Authorization: `Bearer ${tokenStore.access}` },
              withCredentials: true,
              params: { page },
          });
          this.products = data.results;
          this.count = data.count;
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
    async setProductInCart(id: number, count: number) {
      const find = this.products.find((subProduct) => Number(subProduct.product) === id);
      if (!find) {
        await this.setNewProductInCart(id, count);
      } else {
        await this.updateProductInCart(find.id, find.product, count);
        find.amount = count;
      }
    },
    async updateProductInCart(id: number, productId: number, count: number) {
      try {
        this.loading = true;
        const tokenStore = useTokenStore();
    
        const { data } = await axios.patch<IUniversalDataReturns<ShoppingCartItem>>(
          `${process.env.VUE_APP_SERVER}api/shopping_carts/${id}/`,
          {
            amount: count,
            product: productId,
          },
          {
            headers: { Authorization: `Bearer ${tokenStore.access}` },
            withCredentials: true,
          }
        );
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
    async setNewProductInCart(id: number, count: number) {
      try {
        this.loading = true;
        const tokenStore = useTokenStore();
        const { data } = await axios.post<ShoppingCartItem>(process.env.VUE_APP_SERVER + 'api/shopping_carts/',
          {
            amount: count,
            product: id,
            stock: 1,
          },
          {
              headers: { Authorization: `Bearer ${tokenStore.access}` },
              withCredentials: true,
          });
          this.count = this.count + 1;
          this.products.push(data);
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
    async removeProductFromCart(id: number) {
      try {
        this.loading = true;
        const tokenStore = useTokenStore();
        await axios.delete(process.env.VUE_APP_SERVER + `api/shopping_carts/${id}/`, {
          headers: { Authorization: `Bearer ${tokenStore.access}` },
          withCredentials: true,
        });
        this.products = this.products.filter((item) => item.id !== id);
        this.count = this.count - 1;
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
    async clearCart() {
      try {
        this.loading = true;
        const tokenStore = useTokenStore();
        for (const item of this.products) {
          await axios.delete(
            process.env.VUE_APP_SERVER + `api/shopping_carts/${item.id}/`,
            {
              headers: { Authorization: `Bearer ${tokenStore.access}` },
              withCredentials: true,
          });
        }
        this.products = [];
        this.count = 0;
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    }
  },
  persist: true,
});