import { defineStore } from "pinia";
import axios from "axios";
import { useTokenStore } from "@/store/modules/TokenOperationStoreGroup/TokenOperation";
import { CardStoreType, CardStoreState } from "./type";
import { IUniversalDataReturns } from "@/store/type";
import { useCategoriesStore } from "../CategoriesGroup/CategoriesGroup";

export const useCardStore = defineStore("cardStore", {
  state: (): CardStoreState => ({
    loading: true,
    horizontal: true,
    count: 0,
    next: null,
    previous: null,
    results: [],
    search: "",
    pageSize: 25,
  }),
  actions: {
    async getDataFromSearch(category?: number, page = 1) {
      this.loading = true;
      const tokenStore = useTokenStore();
      const check = await tokenStore.checkToken();
      try {
        const { data } = await axios.get<IUniversalDataReturns<CardStoreType>>(
          process.env.VUE_APP_SERVER + 'api/search/',
          {
            params: {
              category,
              page,
              page_size: this.pageSize,
            },
            headers: {
              Authorization: check ? "Bearer " + tokenStore.access : undefined,
            },
          }
        );
        this.results = data.results;
        this.count = data.count;
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
    async getDataSearch(page = 1) {
      this.loading = true;
      const tokenStore = useTokenStore();
      const check = await tokenStore.checkToken();
      try {
        const { data } = await axios.get<IUniversalDataReturns<CardStoreType>>(
          process.env.VUE_APP_SERVER + 'api/search/',
          {
            params: {
              search: this.search,
              page,
              page_size: this.pageSize,
            },
            headers: {
              Authorization: check ? "Bearer " + tokenStore.access : undefined,
            },
          }
        );
        this.results = data.results;
        this.count = data.count;
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
    async searchHandler(page = 1) {
      this.results = [];
      const categoriesStore = useCategoriesStore();
      if(this.search.length) {
        this.getDataSearch(page);
        categoriesStore.getCategories(this.search);
        return;
      }
      if(categoriesStore.select) {
        this.getDataFromSearch(categoriesStore.select, page);
        return;
      }
      this.getDataFromSearch(undefined, page);
    }
  },
});
