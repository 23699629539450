import { defineStore } from 'pinia';
import { Language, TranslateStoreState } from './type';
import { userDataStore } from '../AccountAngabenStoreGroup/UserGroup/userData';

export const useUserTranslate = defineStore('useUserTranslateStore', {
  state: (): TranslateStoreState => ({
    languageSelect: Language.DE,
    language: {
      'Deutsch': Language.DE,
      'French': Language.FR,
      'Italian': Language.IT,
    }
  }),
  actions: {
    async updateLanguageUser (lang: string) {
      const userStore = userDataStore();
      await userStore.updateUserData({
        ui_language: lang.toUpperCase() === 'DEUTSCH' ? 'GERMAN' as Language : lang.toUpperCase() as Language,
      });
    },
  }
});
