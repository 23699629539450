import { useTokenStore } from '@/store/modules/TokenOperationStoreGroup/TokenOperation';
import { changeTitle } from '@/utils/routerGroupUtils/routerTitle/routerTitle';
import { createRouter, createWebHistory } from 'vue-router';

export const router = createRouter({
    routes: [{
        path: '/',
        name: 'start',
        meta: {
            title: 'Roth',
            needAuth: false,
        },
        component: () => import('@/view/WebShopGroups/WebShopGroups.vue'), 
    },
    {
        path: '/product/:id',
        name: 'product-details',
        meta: {
            title: 'Product Details',
            needAuth: false,
        },
        component: () => import('@/view/WebShopGroups/ProductDetail/ProductDetail.vue'),
    },
    {
        name: 'angebot',
        path: '/angebot',
        meta: {
            title: 'Angebot',
            needAuth: false,
        },
        redirect: () => {
            return { name: 'kataloge' }
        },
        component: () => import('@/view/AngeBotGroups/AngeBotGroup/AngeBotGroup.vue'), 
        children: [{
            name: 'kataloge',
            path: 'kataloge',
            meta: {
                title: 'Kataloge',
                needAuth: false,
                breadcrumbs: [
                    { name: 'webshop', to: 'start' },
                    { name: 'kataloge', to: 'kataloge' },
                ],
            },
            component: () => import('@/view/AngeBotGroups/KatalogeGroup/KatalogeGroup.vue'), 
        },
        {
            name: 'maschinenservice',
            path: 'maschinenservice',
            meta: {
                title: 'Maschinen service',
                needAuth: false,
                breadcrumbs: [
                    { name: 'webshop', to: 'start' },
                    { name: 'Maschinen service', to: 'maschinenservice' },
                ],
            },
            component: () => import('@/view/AngeBotGroups/MaschinenserviceGroup/MaschinenserviceGroup.vue'), 
        },
        {
            name: '3d_druck',
            path: '3d_druck',
            meta: {
                title: '3D druck',
                needAuth: false,
                breadcrumbs: [
                    { name: 'webshop', to: 'start' },
                    { name: '3D druck', to: '3d druck' },
                ],
            },
            component: () => import('@/view/AngeBotGroups/3DDruckGroup/3DDruckGroup.vue'), 
        },
        {
            name: 'workshop_and_schuhlungen',
            path: 'workshop_and_schuhlungen',
            meta: {
                title: 'Workshop and Schuhlungen',
                needAuth: false,
                breadcrumbs: [
                    { name: 'webshop', to: 'start' },
                    { name: 'Workshop and Schuhlungen', to: 'Schulungen' },
                ],
            },
            component: () => import('@/view/AngeBotGroups/WorkshopAndSchuhlungenGroup/WorkshopAndSchuhlungenGroup.vue'), 
        }]
    },
    {
        name: 'uber_uns',
        path: '/uber_uns',
        meta: {
            title: 'Uber uns',
            needAuth: false,
        },
        redirect: () => {
            return { name: 'team' }
        },
        component: () => import('@/view/UberUnsGroups/UberUnsGroup/UberUnsGroup.vue'), 
        children: [{
            name: 'team',
            path: 'team',
            meta: {
                title: 'Team',
                needAuth: false,
                breadcrumbs: [
                    { name: 'webshop', to: 'start' },
                    { name: 'team', to: 'team' },
                ],
            },
            component: () => import('@/view/UberUnsGroups/TeamGroup/TeamGroup.vue')
        }, 
        {
            name: 'kontakt',
            path: 'kontakt',
            meta: {
                title: 'Kontakt',
                needAuth: false,
                breadcrumbs: [
                    { name: 'webshop', to: 'start' },
                    { name: 'kontakt', to: 'Kontakt' },
                ],
            },
            component: () => import('@/view/UberUnsGroups/KontaktGroup/KontaktGroup.vue'), 
        }]
    },
    {
        name: 'mein_konto',
        path: '/mein_konto',
        meta: {
            title: 'Mein Konto',
            needAuth: true,
        },
        redirect: () => {
            return { name: 'accountangaben' }
        },
        component: () => import('@/view/MeinKontoGroups/MeinKontoGroups.vue'), 
        children: [
             {
                 name: 'erledigtebestellungen',
                 path: 'erledigtebestellungen',
                 meta: {
                    title: 'Erledigtebestellungen',
                    needAuth: true,
                },
                 component: () => import('@/view/MeinKontoGroups/erledigteBestellungenGroup/erledigteBestellungenGroup.vue'), 
             },
             {
                 name: 'pendentegaben',
                 path: 'pendentegaben',
                 meta: {
                    title: 'Pendente Bestellungen',
                    needAuth: true,
                },
                 component: () => import('@/view/MeinKontoGroups/pendenteBestellungenGroup/pendenteBestellungenGroup.vue'), 
             },
             {
                 name: 'defektmelden',
                 path: 'defektmelden',
                 meta: {
                    title: 'Defekt melden',
                    needAuth: true,
                },
                 component: () => import('@/view/MeinKontoGroups/defektMeldenGroup/defektMeldenGroup.vue'), 
             },
             {
                name: 'accountangaben',
                path: 'accountangaben',
                meta: {
                    title: 'Accountangaben',
                    needAuth: true,
                },
                component: () => import('@/view/MeinKontoGroups/AccountAngabenGroup/AccountAngabenComponent.vue'), 
             },
        ]
    },
    {
        path: '/favorites',
        name: 'favorites',
        meta: {
            title: 'Favorites',
            needAuth: false,
        },
        component: () => import('@/view/FavoriteGroups/FavoriteGroups.vue'), 
    },
    {
        name: 'cart',
        path: '/cart',
        meta: {
            title: 'Cart',
            needAuth: true,
        },
        component: () => import('@/view/CartGroups/CartGroup/CartGroup.vue'), 
        
    },{
        name: 'kasse',
        path: '/kasse',
        meta: {
            title: 'Kasse',
            needAuth: true,
            breadcrumbs: [
                { name: 'webshop', to: 'start' },
                { name: 'warenkorb', to: 'cart' },
                { name: 'versand', to: 'versand' },
            ],
            next: 'versand',
        },
        redirect: () => {
            return { name: 'versand' }
        },
        component: () => import('@/view/KasseGroups/KasseGroup.vue'),
        children: [
            {
                name: 'versand',
                path: 'versand',
                meta: {
                   title: 'Versand',
                   needAuth: true,
                   breadcrumbs: [
                        { name: 'webshop', to: 'start' },
                        { name: 'warenkorb', to: 'cart' },
                        { name: 'versand', to: 'versand' },
                    ],
                    next: 'rezension',
               },
               component: () => import('@/view/KasseGroups/VersandGroup/VersandGroup.vue'),
            },
            {
                name: 'rezension',
                path: 'rezension',
                meta: {
                   title: 'Rezension',
                   needAuth: true,
                   breadcrumbs: [
                        { name: 'webshop', to: 'start' },
                        { name: 'warenkorb', to: 'cart' },
                        { name: 'versand', to: 'versand' },
                        { name: 'rezension', to: 'rezension' },
                    ],
                    next: 'save',
               },
               component: () => import('@/view/KasseGroups/RezensionGroup/RezensionGroup.vue'),
            },
            {
                name: 'zahlung',
                path: 'zahlung',
                meta: {
                   title: 'Zahlung',
                   needAuth: true,
                   breadcrumbs: [
                        { name: 'webshop', to: 'start' },
                        { name: 'warenkorb', to: 'cart' },
                        { name: 'versand', to: 'versand' },
                        { name: 'rezension', to: 'rezension' },
                        { name: 'zahlung', to: 'zahlung ' },
                    ],
                    next: 'save',
               },
               component: () => import('@/view/KasseGroups/ZahlungGroup/ZahlungGroup.vue'),
            },
        ],
    }],
    history: createWebHistory('web/'),
    linkActiveClass: 'link-active'
});

router.beforeEach(async (to, _, next) => {
    const tokenStore = useTokenStore();
    if (to.meta.needAuth) {
        try {
            const check = await tokenStore.checkToken();
            if (check) {
                next();
            } else {
                await tokenStore.refreshAccessToken();
                const refreshedCheck = await tokenStore.checkToken();
                if (refreshedCheck) {
                    next();
                } else {
                    next({ name: 'start' });
                }
            }
        }  catch (e) {
            console.error(e);
          }
    } else {
        next();
    }
});

router.beforeEach((to, _, next) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    if(typeof(to.meta.title) === 'string'){
        changeTitle(to.meta.title);
    }
    next();
});
