import { Translate } from "./type";

export const Italian:Translate = {
    'email is a required field': "L'email è un campo obbligatorio",
    'password is a required field': "La password è un campo obbligatorio",
    'copy address': 'Copia indirizzo',
    'Workshop and Schuhlungen': 'Workshop e formazione',
    kontakt: 'contatto',
    partner: 'partner',
    geschichte: 'storia',
    team: 'team',
    'uber uns': 'su di noi',
    'workshop & schuhlungen': 'workshop e corsi di formazione',
    '3D druck': 'stampante 3D',
    maschinenservice: 'assistenza tecnica',
    kataloge: 'cataloghi',
    angebot: 'offerta',
    geschlossen: 'chiuso',
    uhr: 'orologio',
    'samstag und sonntag': 'sabato e domenica',
    freitag: 'venerdì',
    öffnungszeiten: 'orari di apertura',
    'montag - donnerstag': 'lunedì - giovedì',
    impressum: 'impronta',
    'schuh - und orthopädiebedarf': 'calzature e ortopedia',
    'rudolf roth ag': 'rudolf roth ag',
    'anmelden oder login beantragen': 'richiedi accesso o accedi',
    logout: 'logout',
    webshop: 'negozio online',
    schuhlungen: 'corsi di formazione per calzature',
    'unser team': 'il nostro team',
    'mein konto': 'il mio account',
    'erledigte bestellungen': 'ordini completati',
    'pendente bestellungen': 'ordini in sospeso',
    rückgaben: 'resi',
    'defekt melden': 'segnala un difetto',
    accountangaben: 'informazioni sull\'account',
    'änderungen speichern': 'salva modifiche',
    rechnungsadresse: 'indirizzo di fatturazione',
    'plz': 'CAP',
    'ein land': 'un paese',
    'die stadt': 'la città',
    adresse: 'indirizzo',
    kundennummer: 'numero cliente',
    email: 'email',
    'kennwort ändern': 'modifica password',
    'neue adresse hinzufügen': 'aggiungi nuovo indirizzo',
    kontodetails: 'dettagli dell\'account',
    firma: 'azienda',
    kontaktperson: 'persona di contatto',
    telefonnummer: 'numero di telefono',
    'zusatzliche telefonnummer': 'numero di telefono aggiuntivo',
    'gln-nummer': 'numero GLN',
    'mwst-nummer': 'partita IVA',
    lieferadresse: 'lieferadresse',
    'bestellung wiederholen': "ripetere l'ordine",
    bezahlstatus: "stato del pagamento",
    gesamtbetrag: "importo totale",
    size: "taglia",
    colour: "colore",
    'melden sie sich in ihrem konto an': 'si prega di accedere al proprio account',
    email_login: 'email',
    'email oder Kundennummer': 'email o numero cliente',
    password: 'password',
    'nicht registriert?': 'non registrato?',
    'ein Konto erstellen': 'crea un account',
    'passwort vergessen?': 'hai dimenticato la password?',
    'leer': 'vuoto',
    'wird geladen': 'È in corso il caricamento',
    "willkürlich": "arbitrario",
    "festgelegt": "definito",
    "min length 1": "lunghezza minima 1",
    'bilder hochladen': 'immagini elevate',
    'bestellnummer': 'bestellnummer',
    'defektbeschreibung': 'descrizione del difetto',
    delete: 'delete',
    'in den warenkorb': 'aggiungi al carrello',
    'wonach suchst du?': 'che cosa stai cercando?',
    category: 'categoria',
    'leerer warenkorb': 'carrello della spesa vuoto',
    'zurück zum einkaufen': 'torniamo a fare acquisti',
    bestellen: 'per ordinare',
    'zum warenkorb hinzufügen': 'aggiungi al carrello',
    'menge auf lager':'importo in magazzino',
    favoritinnen: 'preferiti',
    kasse: 'kasse',
    versandart: 'versandart',
    lieferung: 'lieferung',
    'Lieferadresse wählen': 'Scegli indirizzo di consegna',
    'Andere Adresse': 'Altro indirizzo', 
    rezension: 'Recensione',
    zahlung: 'Pagamento',
    "We’re on it!": "Ci stiamo occupando!",
    'Your payment has been received and you\'ll get notifications for your order\'s state.': 'Il pagamento è stato ricevuto e riceverai notifiche sullo stato del tuo ordine.',
    'Zurück zum Einkaufen': 'Torna agli acquisti',
    'Something went wrong': 'Qualcosa è andato storto',
    'Lorem ipsum dolor sit amet consectetur. Quisque arcu amet pretium lectus viverra sit.': 'Lorem ipsum dolor sit amet consectetur. Quisque arcu amet pretium lectus viverra sit.',
    "versand": "Spedizione",
    "post": "Codice postale",
    "warenkorb": "Carrello",
    "Total": "Totale",
    "List": "Lista",
    "Price": "Prezzo",
    "Quantity": "Quantità",
    "Total Price": "Prezzo totale",
    "color": "Colore",
    "Rechnung erhalten per": "Ricevere la fattura tramite",
    "Versandart": "Modalità di spedizione",
    "List of products": "Lista dei prodotti",
    "Photo": "Foto",
    "Warenkorb": "Carrello",
    'Unsere Partner': 'Unsere Partner',
    'Schulungen': 'Schulungen',
    Kataloge: 'Cataloghi',
    Gummi: 'gomma',
    Orthopädie: 'ortopedia',
    "Leder": "pelle",
    "Chemie": "chimica",
    "Beschläge": "ferramenta",
    "Schuhpflege": "cura delle scarpe",
    "Werkzeuge": "attrezzi",
    "Maschinen": "macchine",
    Hauptkatalog: 'catalogo principale',
    anrede: "Titolo",
    vorname: "Nome",
    "Treten sie mit uns in kontakt": "Contattaci",
    nachname: "Cognome",
    strasse: "Via",
    'nr.': "N°",
    'nr': "N°",
    postcode: "CAP",
    ort: "Città",
    "Maschinenservicek": "Servizio di assistenza macchine",
    "Ein Maschinenservice-Anforderungsformular": "Modulo di richiesta di assistenza macchine",
    "name": "nome",
    "maschinenbezeichnung": "denominazione macchina",
    "Maschinennummer": "numero macchina",
    "Problem Beschreibung": "descrizione del problema",
    "Maschinen service": "servizio macchine",
    "Workshop and Schulungen": 'Workshop e corsi',
    phone: "Telefono",
    "Ihre Nachricht": "Il tuo messaggio",
    "Rudolf Roth AG": "Rudolf Roth AG",
    "Schuh- und Orthopädiebedarf": "Articoli per calzature e ortopedia",
    "Öffnungszeiten": "Orari di apertura",
    "Montag - Donnerstag": "Lunedì - Giovedì",
    Freitag: "Venerdì",
    "7.30 Uhr- 12.00 Uhr": "7.30 - 12.00",
    "13.00 Uhr - 17.00 Uhr": "13.00 - 17.00",
    "Samstag und Sonntag": "Sabato e Domenica",
    Geschlossen: "Chiuso",
    Senden: "Invia",
    kataloge_1: "Il nostro catalogo principale è composto da 9 parti. Cliccando sull'immagine o sul testo corrispondente, accederete a un file PDF. Questi cataloghi vengono aggiornati continuamente.",
    'Anfrageformular für einen Workshop': 'Modulo di richiesta per un workshop',
    'Anfrageformular für einen 3D Druck': 'Modulo di richiesta per una stampa 3D',
    "Geschichte_1": "Rudolf Roth AG a Gwatt-Thun (articoli ortopedici, cura delle scarpe, cura della pelle) è dal 1949 un partner competente per calzolai, laboratori ortopedici, negozi specializzati in calzature e negozi di articoli sportivi, un'azienda familiare.",
    "Geschichte_2": "Come specialista ben noto in tutta la Svizzera, l'azienda distribuisce tutti gli articoli utilizzati nell'industria delle calzature tra cui gomma, ortopedia, pelle, prodotti chimici, ferramenta, parti di ricambio, filati, forniture, utensili, macchine e parti di macchine.",
    "Geschichte_3": "La cura delle relazioni commerciali attraverso il contatto personale con il cliente fa parte della filosofia commerciale seria dell'azienda, insieme alla consegna di prodotti di alta qualità ai clienti entro breve tempo.",
    'Ihr Warenkorb': 'Il Tuo Carrello Acquisti',
    'Nettobetrag': 'Importo Netto',
    'MWST':'I.V.A.',
    'Stärke':'Forza',
    'Shore':'Costa',
    'Maßeinheiten':'Dimensioni',
    'WAITING': 'IN ATTESA',
    'OPENED': 'APERTO',
    'ISSUED': 'EMESSO',
    'CANCELED': 'ANNULLATO',
    'CLOSED': 'CHIUSO',
    'NORMAL': 'NORMALE',
    'COMPLETED': 'COMPLETATO',
}
