import { defineStore } from 'pinia';
import { usePopupStoreState } from './type';

export const usePopupStore = (id: string) => defineStore(`usePopupStore_${id}`, {
  state: (): usePopupStoreState => ({
    open: false,
    message: '',
  }),
  actions: {
    changeOpenPopupStore (state: boolean) {
        this.open = state;
    },
  }
})();
